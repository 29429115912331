import React from 'react';
import './ResourcePanel.css';

const ResourcePanel = () => {
  return (
    <div className="resource-panel">
      <h3>Ресурси</h3>
      <p>Поки пусто  &#128522;</p>
      {/* <p>Gold: 100</p>
      <p>Wood: 50</p> */}
    
    </div>
  );
}

export default ResourcePanel;
