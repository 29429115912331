import React from 'react';
import './SoundPanel.css';

const SoundPanel = ({
  bgVolume,
  effectsVolume,
  overallVolume,
  onBgVolumeChange,
  onEffectsVolumeChange,
  onOverallVolumeChange,
  onToggleSound,
  soundEnabled
}) => {
  return (
    <div className="resource-panel">
        <h3>Звук</h3>

        <div className="volume-control">
            <button
                onClick={onToggleSound}
                className={soundEnabled ? 'sound-on' : 'sound-off'}
            >
                {soundEnabled ? 'Mute Sound' : 'Unmute Sound'}
            </button>
        </div>

      <div className="volume-control">
        
        <h4 className='h4'>Background Music Volume</h4>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={bgVolume}
          onChange={(e) => onBgVolumeChange(e.target.value)}
          style={{ width: '100%' }}
        />
      </div>

      <div className="volume-control">
        <h4 className='h4'>Sound Effects Volume</h4>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={effectsVolume}
          onChange={(e) => onEffectsVolumeChange(e.target.value)}
          style={{ width: '100%' }}
        />
      </div>

      <div className="volume-control">
        <h4 className='h4'>Overall Volume</h4>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={overallVolume}
          onChange={(e) => onOverallVolumeChange(e.target.value)}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
}

export default  SoundPanel;
